const endPoint = {
  login: "api/security/login",
  produits:"/api/produits",
  categories:"/api/categories",
  responsables:"/api/responsables",
  sousresponsables:"/api/sousresponsables",
  clients:"/api/clients",
  stocks:"/api/stocks",
  users:"/api/users",
  livreurs:"/api/livreurs",
  statistiques:"/api/statistiques",
  administrateurs:"/api/administrateurs",
  commandes:"/api/commandes",
  promotions:"api/promotions",
  boutiques:"/api/boutiques",
  commandeLivreurs:"/api/commande-livreurs"

};

export default endPoint;

import { useNavigate } from "react-router-dom";
import loginImg from "../assets/images/login.png";
import logo from "../assets/images/logo-1.jpg";
import AppLink from "../routers/AppLink";
import request from "../services/request";
import endPoint from "../services/endPoint";
import { useFormik } from "formik";
import { Input } from "../components/Input";
import { useContext, useEffect } from "react";
import { AppContext } from "../services/context";
import { useState } from "react";

const initLogin = {
  login: "adminfranc@gmail.com",
  password: "eE@dminfr@nc",

//     adminpierre@gmail.com            eE@dminµie$$e
  //   adminfranc@gmail.com              eE@dminfr@nc
};

export const Login = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AppContext);
  const { user, onUserChange } = authCtx;
  const [message, setMessage] = useState({
    isSuccess: false,
    content: "",
    form: "getOTP",
  });
  useEffect(() => {
    isAuth();
  }, [user.isAuth]);
  const formik = useFormik({
    initialValues: initLogin,
    onSubmit: (values) => {
      console.log(values);
      login(values);
    },
  });

  const login = (values) => {
    request
      .post(endPoint.login, values)
      .then((res) => {
        let nom = "";
        console.log(res.data);

        if (
          res.data.user.administrateur !== null ||
          res.data.user.isActive === true &&
          res.data.user.role === "ADMIN"
        ) {
          nom = res.data.user.administrateur.nom +
              " " +
              res.data.user.administrateur.prenom;
          

          onUserChange({
            isAuth: true,
            type: "",
            name: nom,
            photo: res.data.user.photo,
            role: res.data.user.role,
            isActive: res.data.user.isActive,
            //boutique: res.data.user.responsable.boutique.id,
            //roles: res.data.roles,
            token: res.data.accessToken,
            refreshToken: res.data.refreshToken,
          });
          isAuth();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const rest = (e) => {
    e.preventDefault();
    setMessage({
      ...message,
      content: "Envoi du code en cours...",
    });
    request
      .put(endPoint.users + "/passwordforget/" + formik.values["email"])
      .then((res) => {
        console.log(res.data);
        setMessage({
          isSuccess: true,
          content: res.data.message,
          form: "sendOTP",
        });
      })
      .catch((error) => {
        console.log(error);
        setMessage({
          isSuccess: false,
          content: error.response.data.message,
        });
      });
  };

  const getPassword = (e) => {
    e.preventDefault();
    const data = {
      email: formik.values["email"],
      otp: formik.values["otp"],
    };
    request
      .put(endPoint.users + "/reset-password-by-email-otp", data)
      .then((res) => {
        console.log(res.data);
        setMessage({
          isSuccess: true,
          content: res.data.message,
          form: "",
        });
      })
      .catch((error) => {
        console.log(error);
        setMessage({
          isSuccess: false,
          content: error.response.data.message,
        });
      });
  };

  const isAuth = () => {
    if (user.isAuth === true && user.token != null && user.token !== "") {
      console.log(`connexion reussi, isAuth: ${user}`);
      console.log(user);

      return navigate(AppLink.dashboard + "/" + AppLink.accueil);
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-3 mx-auto">
          <div className="h-100 pt-5 position-relative">
            <div className="text-start">
              <img width="100px" src={logo} alt="login image" />
              <h1>Connexion</h1>
              <p>Heureux de vous revoir</p>
            </div>
            <Input
              type={"text"}
              placeholder="Email"
              name={"login"}
              formik={formik}
            />
            <Input
              type={"password"}
              placeholder="Mot de passe"
              name={"password"}
              formik={formik}
            />
            <button
              className="btn btn-primary w-100"
              onClick={formik.handleSubmit}
            >
              Se connecter
            </button>
            <p className="text-start mt-3">
              En cas de perte du mot de passe, veuillez contacter un
              administrateur{" "}
              <span
                className="text-primary cursor fw-bold"
                data-bs-toggle="modal"
                data-bs-target="#forgetPasse"
              >
                cliquez ici
              </span>
            </p>
            <p className="position-absolute1 bottom-01 mt-5">
              © 2023 Mycourses.com. Tous droits reservés.
            </p>
          </div>
        </div>
        <div className="d-none d-md-block col-md-9 px-0">
          <div className="login-img-container overflow-hidden">
            <img className="login-img" src={loginImg} alt="login image" />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="forgetPasse"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Réinitialisation du mot de passe
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              {message.form === "getOTP" && (
                <>
                  <span className="d-block mb-3">
                    Entrez votre adresse mail pour recevoir votre code
                    réinitialisation
                  </span>
                  <Input
                    type={"text"}
                    placeholder="Email"
                    name={"email"}
                    formik={formik}
                  />
                </>
              )}
              <p
                className={
                  message.isSuccess
                    ? "text-success fw-bold"
                    : "text-danger fw-bold"
                }
              >
                {message.content}
              </p>
              {message.form === "sendOTP" && (
                <>
                  <span className="d-block mb-3">Entrez le code OTP</span>
                  <Input
                    type={"text"}
                    placeholder="Code OTP"
                    name={"otp"}
                    formik={formik}
                  />
                  <div>
                    <span className="text-warning fw-bold me-2" onClick={rest}>
                      Renvoyer le code
                    </span>
                    <br />
                    <span
                      className="text-danger fw-bold me-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setMessage({
                          ...message,
                          form: "getOTP",
                        });
                      }}
                    >
                      Modifier l'adresse mail ({formik.values["email"]})
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                //ref={"closeEditImage"}
              >
                Annuler
              </button>
              {message.form !== "" && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={message.isSuccess ? getPassword : rest}
                >
                  Valider
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

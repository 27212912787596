export const HomeIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 10.25C5 10.25 6.2 11.75 8 11.75C9.8 11.75 11 10.25 11 10.25M1.25 9.94995V8.09755C1.25 7.23602 1.25 6.80525 1.36104 6.40855C1.4594 6.05715 1.62105 5.72664 1.83804 5.43326C2.08301 5.10206 2.42303 4.83759 3.10308 4.30866L5.05309 2.792C6.10706 1.97224 6.63405 1.56236 7.21596 1.4048C7.72941 1.26578 8.27059 1.26578 8.78404 1.4048C9.36595 1.56236 9.89294 1.97224 10.9469 2.792L12.8969 4.30867C13.577 4.83759 13.917 5.10206 14.162 5.43326C14.379 5.72664 14.5406 6.05715 14.639 6.40855C14.75 6.80525 14.75 7.23602 14.75 8.09755V9.94995C14.75 11.6301 14.75 12.4702 14.423 13.1119C14.1354 13.6764 13.6765 14.1354 13.112 14.423C12.4702 14.75 11.6302 14.75 9.95 14.75H6.05C4.36984 14.75 3.52976 14.75 2.88803 14.423C2.32354 14.1354 1.8646 13.6764 1.57698 13.1119C1.25 12.4702 1.25 11.6301 1.25 9.94995Z"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

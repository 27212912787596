export const IconContainer = ({children}) => {
  return (
    <>
      <span className="d-inline-block d-flex align-items-center justify-content-center rounded-5 icon-circle">
        <span className="d-flex h-100 align-items-center justify-content-center">
          {children}
        </span>
      </span>
    </>
  );
};

export const EditIcon = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66667 5.74991H3.61667C2.96327 5.74991 2.63657 5.74991 2.38701 5.87707C2.16749 5.98892 1.98901 6.1674 1.87716 6.38692C1.75 6.63649 1.75 6.96319 1.75 7.61658V10.8832C1.75 11.5366 1.75 11.8633 1.87716 12.1129C1.98901 12.3324 2.16749 12.5109 2.38701 12.6228C2.63657 12.7499 2.96327 12.7499 3.61666 12.7499H10.3757C11.0291 12.7499 11.3558 12.7499 11.6054 12.6228C11.8249 12.5109 12.0034 12.3324 12.1152 12.1129C12.2424 11.8633 12.2424 11.5366 12.2424 10.8832V7.49991M3.5 9.24991H3.50583M5.83333 9.24988H5.83917M6.71937 8.02171L7.48633 7.87014C7.90018 7.78836 8.1071 7.74746 8.30006 7.67213C8.47133 7.60527 8.6341 7.5184 8.78498 7.41333C8.95496 7.29495 9.10411 7.1458 9.40241 6.84751L12.4057 3.84425C12.8066 3.44335 12.8066 2.79337 12.4057 2.39247C12.0048 1.99157 11.3548 1.99157 10.9539 2.39247L7.89929 5.44706C7.61978 5.72657 7.48002 5.86633 7.36715 6.02475C7.26694 6.1654 7.18266 6.31673 7.11585 6.47597C7.04059 6.65535 6.99536 6.84775 6.9049 7.23254L6.71937 8.02171Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

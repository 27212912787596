export const CommandeIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.72482 3.75H15.75L14.25 9H5.53253M15 12H6L4.5 2.25H2.25M6.75 15C6.75 15.4142 6.41421 15.75 6 15.75C5.58579 15.75 5.25 15.4142 5.25 15C5.25 14.5858 5.58579 14.25 6 14.25C6.41421 14.25 6.75 14.5858 6.75 15ZM15 15C15 15.4142 14.6642 15.75 14.25 15.75C13.8358 15.75 13.5 15.4142 13.5 15C13.5 14.5858 13.8358 14.25 14.25 14.25C14.6642 14.25 15 14.5858 15 15Z"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

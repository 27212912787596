import { DeleteIcon } from "../assets/icons/DeleteIcon";
import { EditIcon } from "../assets/icons/EditIcon";
import { EyeIcon } from "../assets/icons/EyeIcon";
import { PrevIcon } from "../assets/icons/PrevIcon";
import { SearchIcon } from "../assets/icons/SearchIcon";
import { SuivIcon } from "../assets/icons/SuivIcon";
import AppRoute from "../routers/AppRoute";

export const Livreur = ({title}) => {
  return (
    <>
      <AppRoute type={"livreur"} />
    </>
  );
};

import { useNavigate } from "react-router-dom";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { EditIcon } from "../../assets/icons/EditIcon";
import { EyeIcon } from "../../assets/icons/EyeIcon";
import { PrevIcon } from "../../assets/icons/PrevIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { SuivIcon } from "../../assets/icons/SuivIcon";
import AppLink from "../../routers/AppLink";
import endPoint from "../../services/endPoint";
import request from "../../services/request";
import { pagination } from "../../services/function";
import { useEffect, useRef, useState } from "react";

export const ListClient = ({ title }) => {
  const navigate = useNavigate();
  const [datas, setDatas] = useState({
    all: [],
    small: [],
  });
  const [viewData, setViewData] = useState({});
  const [pages, setPages] = useState({
    list: [],
    counter: 0,
    index: 0,
  });

  const close = useRef();
  useEffect(() => {
    get();
  }, []);

  const get = () => {
    request
      .get(endPoint.clients)
      .then((res) => {
        const tab = pagination(res.data.clients.data, 10);

        console.log(tab);

        if (tab.counter !== 0) {
          setDatas({
            all: res.data.clients.data,
            small: tab.list[0],
          });
          setPages(tab);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const destroy = (e) => {
    e.preventDefault();

    request
      .put(endPoint.users + "/activate/" + viewData.user.id)
      .then((res) => {
        console.log(res.data);
        //close.current.click();
        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changePage = (e, values) => {
    e.preventDefault();
    const pageNumber = pages.index + parseInt(values);
    console.log(pageNumber);
    if (pageNumber >= 0 && pageNumber < pages.counter) {
      setPages({ ...pages, index: pageNumber });
      setDatas({
        ...datas,
        small: pages.list[pageNumber],
      });
    }
  };

  const gotoDetail = (e, id) => {
    e.preventDefault();
    navigate(AppLink.detail + "/" + id);
  };
  const selectedData = (e, data) => {
    e.preventDefault();
    setViewData(data);
  };
  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <h1 className="text-start mb-3">Clients</h1>
          <div className="d-flex">
            <div className="d-flex align-items-center me-auto">
              <div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Rechercher..."
                  />
                  <span className="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
              <div>
                <span className="ms-2" onClick={(e) => changePage(e, "-1")}>
                  <PrevIcon />
                </span>
                <span className="ms-2" onClick={(e) => changePage(e, "+1")}>
                  <SuivIcon />
                </span>
              </div>
              <span className="fw-bold">
                Page {pages.index + 1} / {pages.list.length}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nom Prénom</th>
                <th scope="col">Contact</th>
                <th scope="col">Genre</th>
                <th scope="col">Etat du compte</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {datas.small.map((data, idx) => {
                return (
                  <tr key={idx}>
                    <th scope="row">{idx + 1}</th>
                    <td>{data.nom + " " + data.prenom}</td>
                    <td>{data.user?.numeroTelephone}</td>
                    <td>{data.genre === "F" ? "Femme" : "Homme"}</td>
                    <td className="fw-bold">
                      {" "}
                      {data.user?.isActive ? (
                        <span className="badge bg-success text-white">
                          Actif
                        </span>
                      ) : (
                        <span className="badge bg-danger text-white">
                          Non Actif
                        </span>
                      )}
                    </td>
                    <td>
                      <div className="btn-group">
                        <button
                          className="btn btn-primary mx-1 rounded-3"
                          onClick={(e) => gotoDetail(e, data.id)}
                        >
                          <EyeIcon /> Voir
                        </button>

                        <button
                          className="btn btn-danger mx-1 rounded-3"
                          data-bs-toggle="modal"
                          data-bs-target="#destroy"
                          onClick={(e) => selectedData(e, data)}
                        >
                          <DeleteIcon /> Banir
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div
        class="modal fade"
        id="destroy"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                {viewData.user?.isActive ? "Désactivation " : "Activation "}
                du compte
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="d-flex">
                <img
                  className="rounded-3"
                  width={"172px"}
                  src="https://source.unsplash.com/random/800x800/?person=1"
                  alt=""
                />
                <div className="ms-2 text-start w-100">
                  <div className="d-flex w-100">
                    <h3 className="me-auto">
                      {viewData.nom + " " + viewData.prenom}
                    </h3>
                    <span>
                      {viewData.user?.isActive ? "Actif" : "Non actif"}
                    </span>
                  </div>
                  <span>{viewData?.genre === "F" ? "Femme" : "Homme"}</span>
                  <br />
                  <span>{viewData.user?.email}</span>
                  <br />
                  <span>{viewData.user?.numeroTelephone}</span>
                  <br />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" data-bs-dismiss="modal">
                Annuler
              </button>
              <button
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={(e) => destroy(e)}
              >
                Continuer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

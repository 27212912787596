

import { useNavigate, useParams } from "react-router-dom";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { EditIcon } from "../../assets/icons/EditIcon";
import { EyeIcon } from "../../assets/icons/EyeIcon";
import { PrevIcon } from "../../assets/icons/PrevIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { SuivIcon } from "../../assets/icons/SuivIcon";
import AppLink from "../../routers/AppLink";
import request from "../../services/request";
import endPoint from "../../services/endPoint";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { pagination } from "../../services/function";

export const TabCategorie = ({title}) => {
  const {id} = useParams()

    const navigate = useNavigate()
    const [datas, setDatas] = useState({
      all: [],
      small: [],
    });
    const [viewData, setViewData] = useState("");
    const [pages, setPages] = useState({
      list: [],
      counter: 0,
      index: 0,
    });
    
    const close = useRef();
    useEffect(() => {
      get();
    }, []);
    
    const get = () => {
      request
        .get(endPoint.categories+"/by/boutique/"+id)
        .then((res) => {
          const tab = pagination(res.data.categories.data, 10);
  
          console.log(res);
  
          if (tab.counter !== 0) {
            setDatas({
              all: res.data.categories.data,
              small: tab.list[0],
            });
            setPages(tab);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    
    const destroy = (e) => {
      e.preventDefault()
      
      request
        .delete(endPoint.produits+"/"+viewData.id)
        .then((res) => {
          //console.log(res.data);
          close.current.click();
          get();
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    const changePage = (e, values) => {
      e.preventDefault();
      const pageNumber = pages.index + parseInt(values);
      console.log(pageNumber);
      if (pageNumber >= 0 && pageNumber < pages.counter) {
        setPages({ ...pages, index: pageNumber });
        setDatas({
          ...datas,
          small: pages.list[pageNumber],
        });
      }
    };
  
    const gotoDetail = (e, id) => {
        e.preventDefault()
        navigate(AppLink.detail+"/"+id)
    }
    const selectedData = (e, data) => {
      e.preventDefault();
      setViewData(data);
    };
    
  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <h1 className="text-start mb-3">Liste des catégories de la boutique</h1>
          <div className="d-flex align-items-center">
            <div>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Rechercher..."
                />
                <span class="input-group-text">
                  <SearchIcon />
                </span>
              </div>
            </div>
            <div>
            <span className="ms-2" onClick={(e) => changePage(e, "-1")}>
                  <PrevIcon />
                </span>
                <span className="ms-2" onClick={(e) => changePage(e, "+1")}>
                  <SuivIcon />
                </span>
            </div>
            <span className="fw-bold">Page {pages.index + 1} / {pages.list.length}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Catégories</th>
                <th scope="col">Code</th>
                <th scope="col">Créer le</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {datas.small.map((data, idx) => {
                if (data.deleted) {
                  return null;
                }
                return (
                  <tr key={idx}>
                    <th scope="row">{idx + 1}</th>
                    <td>
                      <div className="d-flex">
                        <div className="ms-2">{data.label}</div>
                      </div>
                    </td>
                    <td>{data.code}</td>
                    <td>{new Date(data.createdAt).toLocaleDateString()}</td>
                    <td>
                      <div className="btn-group1">
                        <button
                          className="btn btn-primary-light mx-1 rounded-3"
                          data-bs-toggle="modal"
                          data-bs-target="#view"
                          onClick={(e) => selectedData(e, data)}
                        >
                          <EyeIcon /> Voir
                        </button>
                        
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal fade modal-lg" id="view">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Détails
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <div className="d-flex">
                <div className="ps-3">
                  <span className="fw-bold fs-20">{viewData.label}</span><br />
                  <span>Code : </span>
                  <span className="text-muted">{viewData.code}</span><br /><br/>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

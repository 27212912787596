export const ArticleIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.00001 5.25H15M3.75001 3H14.25M5.17198 15H12.828C13.5954 15 13.979 15 14.2832 14.8563C14.5512 14.7297 14.7757 14.5265 14.9283 14.2725C15.1016 13.9841 15.1398 13.6023 15.2161 12.8388L15.5521 9.47911C15.6207 8.79332 15.655 8.45043 15.5424 8.18538C15.4436 7.95256 15.2692 7.75985 15.0474 7.63833C14.7948 7.5 14.4502 7.5 13.761 7.5H4.23898C3.54978 7.5 3.20518 7.5 2.95264 7.63833C2.73081 7.75985 2.55641 7.95256 2.45757 8.18538C2.34505 8.45043 2.37934 8.79332 2.44792 9.47911L2.78389 12.8388C2.86024 13.6023 2.89841 13.9841 3.0717 14.2725C3.22433 14.5265 3.44879 14.7297 3.71676 14.8563C4.021 15 4.40466 15 5.17198 15ZM11.25 11.25C11.25 12.0784 10.2426 12.75 9.00001 12.75C7.75737 12.75 6.75001 12.0784 6.75001 11.25C6.75001 10.4216 7.75737 9.75 9.00001 9.75C10.2426 9.75 11.25 10.4216 11.25 11.25Z"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const SuperMarcherIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 7.5L13.8859 13.0707C13.7484 13.7579 13.6797 14.1015 13.5003 14.3586C13.342 14.5852 13.1243 14.7638 12.871 14.8745C12.5838 15 12.2333 15 11.5325 15H6.46753C5.76667 15 5.41624 15 5.12901 14.8745C4.87573 14.7638 4.65795 14.5852 4.49973 14.3586C4.32031 14.1015 4.25159 13.7579 4.11414 13.0707L3 7.5M15 7.5H13.5M15 7.5H15.75M3 7.5H2.25M3 7.5H4.5M4.5 7.5H13.5M4.5 7.5L6.75 3M13.5 7.5L11.25 3M6.75 9.75V12M9 9.75V12M11.25 9.75V12"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

import { useNavigate, useParams } from "react-router-dom";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { EditIcon } from "../../assets/icons/EditIcon";
import { EyeIcon } from "../../assets/icons/EyeIcon";
import { PrevIcon } from "../../assets/icons/PrevIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { SuivIcon } from "../../assets/icons/SuivIcon";
import AppLink from "../../routers/AppLink";
import request, { URL } from "../../services/request";
import endPoint from "../../services/endPoint";
import { useEffect, useState } from "react";
import { pagination } from "../../services/function";

export const DetailCommande = ({ title }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [detail, setDetail] = useState({ boutique: {} });

  const [datas, setDatas] = useState({
    all: [],
    small: [],
  });
  const [viewData, setViewData] = useState("");
  const [pages, setPages] = useState({
    list: [],
    counter: 0,
    index: 0,
  });

  useEffect(() => {
    get();
  }, []);

  const get = () => {
    request
      .get(endPoint.commandes + "/" + id)
      .then((res) => {
        console.log(res.data.commande.data);
        setDetail(res.data.commande.data);
        const tab = pagination(res.data.commande.data.ligneCommandes, 10);

        //console.log(tab);

        if (tab.counter !== 0) {
          setDatas({
            all: res.data.commande.data.ligneCommandes,
            small: tab.list[0],
          });
          setPages(tab);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changePage = (e, values) => {
    e.preventDefault();
    const pageNumber = pages.index + parseInt(values);
    console.log(pageNumber);
    if (pageNumber >= 0 && pageNumber < pages.counter) {
      setPages({ ...pages, index: pageNumber });
      setDatas({
        ...datas,
        small: pages.list[pageNumber],
      });
    }
  };

  const gotoDetail = (e, id) => {
    e.preventDefault();
    navigate(AppLink.detail + "/" + id);
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-6 p-3">
          <div className="bg-gray rounded-3 p-2">
            <div className="d-flex">
              <div className="ms-auto">
                <button className="btn btn-success mx-1">{detail.status}</button>
              </div>
            </div>
            <div className="d-flex align-items-center text-start">
              <img
                className="rounded-circle"
                width={"172px"}
                src="https://source.unsplash.com/random/800x800/?person=1"
                alt=""
              />
              <div className="ms-3 w-100">
                <h2 className="">Réference: {detail.codeCommande}</h2>
                <p className="p-0 m-0 text-start">
                  <span className="text-muted">Statut :</span>{" "}
                  <span className="fw-bold">{detail.status}</span>
                </p>
                <p className="p-0 m-0 text-start">
                  <span className="text-muted">Date :</span>{" "}
                  <span className="fw-bold">
                    {new Date(detail.dateCommande).toLocaleDateString()}
                  </span>
                </p>
                <p className="p-0 m-0 text-start">
                  <span className="text-muted">Client :</span>{" "}
                  <span className="fw-bold">
                    {detail.client?.nom + " " + detail.client?.prenom}
                  </span>
                </p>
                <p className="p-0 m-0 text-start">
                  <span className="text-muted">Email :</span>{" "}
                  <span className="fw-bold">{detail.client?.user?.email} </span>
                </p>
                <p className="p-0 m-0 text-start">
                  <span className="text-muted">Tel : </span>
                  <span className="fw-bold">
                    {detail.client?.user?.numeroTelephone}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 p-3">
          <div className="bg-gray rounded-3 h-100">
            <div className="d-flex align-items-center justify-content-center bg-gray rounded-3 h-100 p-2">
              <div>
                <span className="w-100">Total commande</span> <br />
                <span className="fw-bold">{detail.montantTotal} FCFA</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 p-3">
          <div className="d-flex align-items-center justify-content-center bg-gray rounded-3 h-100 p-2">
            <div>
              <span className="w-100">Total livraison</span> <br />
              <span className="fw-bold">{detail.coutLivraison} FCFA</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <h1 className="text-start mb-3">Liste des produits commandés</h1>
          <div className="d-flex align-items-center">
            <div>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Rechercher..."
                />
                <span class="input-group-text">
                  <SearchIcon />
                </span>
              </div>
            </div>
            <div>
                <span className="ms-2" onClick={(e) => changePage(e, "-1")}>
                  <PrevIcon />
                </span>
                <span className="ms-2" onClick={(e) => changePage(e, "+1")}>
                  <SuivIcon />
                </span>
              </div>
              <span className="fw-bold">
                Page {pages.index + 1} / {pages.list.length}
              </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Produits</th>
                <th scope="col">Catégories</th>
                <th scope="col">Prix</th>
                <th scope="col">Quantité</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {datas.small.map((data, idx) => {
                return (
                  <tr key={idx}>
                    <th scope="row">{idx + 1}</th>
                    <td>{data.produit.label}</td>
                    <td>{data.produit.categories[0]?.label}</td>
                    <td>{data.prixUnitaire} Fcfa</td>
                    <td>{data.quantite}</td>
                    <td>
                      <div className="btn-group">
                        <button
                          className="btn btn-primary-light mx-1 rounded-3"
                          data-bs-toggle="modal"
                          data-bs-target="#view"
                          onClick={(e) => setViewData(data)}
                        >
                          <EyeIcon /> Voir
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div
        class="modal fade"
        id="view"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Information sur le produit
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="d-flex">
                <img
                  width={"200px"}
                  src={URL + "produit/images/" + viewData.produit?.image}
                  alt=""
                />
                <div className="ps-3 text-start">
                  <div className="fw-bold fs-20 mb-1">{viewData.produit?.label}</div>
                  <div className="mb-1">
                    <span>Prix : </span>
                    <span className="fw-bold">
                      {viewData.prixUnitaire + " FCFA"}
                    </span>
                  </div>
                  <div className="mb-1">
                    <span>quantité : </span>
                    <span className="fw-bold">{viewData.quantite}</span>
                  </div>
                  <div className="mb-1">
                    <span className="fw-bold">Description </span>
                    <div>{viewData.produit?.description}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

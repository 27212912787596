
const AppLink = {
    all:"/*",
    login:"/",
    index:"/",
    detail_id:"detail/:id",
    detail:"detail",
    dashboard:"/dashboard",
    accueil:"accueil",
    superMarches:"superMarches",
    articles:"articles",
    commandes:"commandes",
    produits:"produits",
    paiements:"paiements",
    clients:"clients",
    livreur:"livreur",
    demandeLivreur:"demande-livreur",
    admin:"admin",
    moderation:"moderation",
    personnel:"le-personnel",
    boiteReception:"boite-reception",
    monCompte:"mon-compte",
    statistique:"statistique"
}

export default AppLink
import * as React from "react";
import Map, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import marker from "../assets/images/market.png";

function ViewMap() {
  const list = [
    {
      latitude: 12.362991818107886,
      longitude: -1.5280063876616823,
    },
    {
      latitude: 12.358147096585974,
      longitude: -1.4883288816193458,
    },
    
    
  ];
  return (
    <Map
      mapboxAccessToken="pk.eyJ1IjoiZGFib25uZSIsImEiOiJjbHA0ajEyaGwxYnc0MnJxb3BwaTdkNGF2In0.epq-DSHVUNzb2YIemwLlSw"
      initialViewState={{
        latitude: 12.37185834002468,
        longitude: -1.5207072238142711,
        //zoom: 14,
        zoom: 12,
      }}
      style={{ width: "100%", height: "600px" }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
    >
      {list.map((data, idx) => {
        return (
          <Marker
          key={idx}
            longitude={data.longitude}
            latitude={data.latitude}
            anchor="bottom"
          >
            <Icon />
          </Marker>
        );
      })}
    </Map>
  );
}

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="#FF0000"
      class="bi bi-geo-alt-fill"
      viewBox="0 0 16 16"
    >
      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
    </svg>
  );
};

export default ViewMap;

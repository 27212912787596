import { useNavigate } from "react-router-dom";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { EditIcon } from "../../assets/icons/EditIcon";
import { EyeIcon } from "../../assets/icons/EyeIcon";
import { PrevIcon } from "../../assets/icons/PrevIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { SuivIcon } from "../../assets/icons/SuivIcon";
import AppLink from "../../routers/AppLink";
import request from "../../services/request";
import endPoint from "../../services/endPoint";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { pagination } from "../../services/function";
import ViewMap from "../../components/ViewMap";

export const ListeSuperMarche = ({ title }) => {
  const navigate = useNavigate();
  const [datas, setDatas] = useState({
    all: [],
    small: [],
  });
  const [viewData, setViewData] = useState("");
  const [pages, setPages] = useState({
    list: [],
    counter: 0,
    index: 0,
  });
  const [viewCarte, setViewCarte] = useState(false);

  const close = useRef();
  useEffect(() => {
    get();
  }, []);

  const get = () => {
    request
      .get(endPoint.responsables)
      .then((res) => {
        const tab = pagination(res.data.responsables.data, 10);

        console.log(tab);

        if (tab.counter !== 0) {
          setDatas({
            all: res.data.responsables.data,
            small: tab.list[0],
          });
          setPages(tab);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const destroy = (e) => {
    e.preventDefault();

    request
      .delete(endPoint.produits + "/" + viewData.id)
      .then((res) => {
        //console.log(res.data);
        close.current.click();
        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changePage = (e, values) => {
    e.preventDefault();
    const pageNumber = pages.index + parseInt(values);
    console.log(pageNumber);
    if (pageNumber >= 0 && pageNumber < pages.counter) {
      setPages({ ...pages, index: pageNumber });
      setDatas({
        ...datas,
        small: pages.list[pageNumber],
      });
    }
  };

  const gotoDetail = (e, id) => {
    e.preventDefault();
    navigate(AppLink.detail + "/" + id);
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <h1 className="text-start mb-3">Les superMarchés</h1>
          <div className="d-flex align-items-center">
            <div>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Rechercher..."
                />
                <span class="input-group-text">
                  <SearchIcon />
                </span>
              </div>
            </div>
            <div>
              <span className="ms-2" onClick={(e) => changePage(e, "-1")}>
                <PrevIcon />
              </span>
              <span className="ms-2" onClick={(e) => changePage(e, "+1")}>
                <SuivIcon />
              </span>
            </div>
            <span className="fw-bold">
              Page {pages.index + 1} / {pages.list.length}
            </span>
            <button
              className="btn btn-primary ms-auto"
              onClick={(e) => setViewCarte(!viewCarte)}
            >
              Voir la {viewCarte ? " liste":" carte "}
            </button>
          </div>
        </div>
      </div>
      {!viewCarte ? (
        <div className="row">
          <div className="col-12">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nom</th>
                  <th scope="col">Responsable</th>
                  <th scope="col">Contact du responsable</th>
                  <th scope="col">Orange money</th>
                  <th scope="col">Détail</th>
                </tr>
              </thead>
              <tbody>
                {datas.small.map((data, idx) => {
                  return (
                    <tr key={idx}>
                      <th scope="row">{idx + 1}</th>
                      <td>{data.boutique.nom}</td>
                      <td>{data.nom + " " + data.prenom}</td>
                      <td>
                        <span>{data.user.email}</span> <br />
                        <span>{data.user.numeroTelephone}</span>
                      </td>
                      <td>
                        <span>{data.boutique.numeroCompte}</span>
                      </td>
                      <td>
                        <div className="btn-group">
                          <button
                            className="btn btn-primary-light mx-1 rounded-3"
                            onClick={(e) => gotoDetail(e, data.id)}
                          >
                            <EyeIcon /> Voir
                          </button>
                          <button className="btn btn-danger mx-1 rounded-3">
                            <EyeIcon /> Bloquer
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <ViewMap />
          </div>
        </div>
      )}
    </>
  );
};

import { useEffect, useRef, useState } from "react";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { EditIcon } from "../../assets/icons/EditIcon";
import { EyeIcon } from "../../assets/icons/EyeIcon";
import { PrevIcon } from "../../assets/icons/PrevIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { SuivIcon } from "../../assets/icons/SuivIcon";
import endPoint from "../../services/endPoint";
import request, { URL } from "../../services/request";
import { Input } from "../../components/Input";
import { useFormik } from "formik";
import { pagination } from "../../services/function";
import { ArticleIcon } from "../../assets/icons/ArticleIcon";
import { useParams } from "react-router-dom";

const initPromo = {
  dateDebut: "",
  dateFin: "",
  prixPromotion: "",
};
export const TabPromotion = () => {
  const [datas, setDatas] = useState({
    all: [],
    small: [],
  });
  const [categories, setCategories] = useState([]);
  const [viewData, setViewData] = useState("");
  const closePromo = useRef();
  const [statutPromo, setStatutPromo] = useState("en-cours")
  const [pages, setPages] = useState({
    list: [],
    counter: 0,
    index: 0,
  });
  const stats = [
    { name: "Catégories", value: "1250", icon: <ArticleIcon /> },
    { name: "Produits", value: "525.125 XOF", icon: <ArticleIcon /> },
    { name: "Rupture de Stocks", value: "325", icon: <ArticleIcon /> },
    { name: "Promotions", value: "325", icon: <ArticleIcon /> },
  ];
  const typePromo = ["passe", "avenir", "en-cours"];
  const { id } = useParams();
  const close = useRef();
  useEffect(() => {
    get();
  }, []);
  const formikPromo = useFormik({
    initialValues: initPromo,
    onSubmit: (values) => {
      //values.produit = viewData.id;
      //values.status = "status"
      console.log(values);
      update(values);
    },
  });
  const get = (statut = "en-cours") => {
    request
      .get(endPoint.promotions + "/boutiques/" + id + "/"+statut)
      .then((res) => {
        console.log(res.data);
        const tab = pagination(res.data.promotions.data, 10);

        console.log(tab);

        if (tab.counter !== 0) {
          setDatas({
            all: res.data.promotions.data,
            small: tab.list[0],
          });
          setPages(tab);
        }else{
          setDatas({
            all: [],
            small: [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const update = (values) => {
    request
      .put(endPoint.promotions + "/" + values.id, values)
      .then((res) => {
        //console.log(res.data);
        close.current.click();
        //formik.resetForm()

        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const destroy = (e) => {
    e.preventDefault();

    request
      .delete(endPoint.produits + "/" + viewData.id)
      .then((res) => {
        //console.log(res.data);
        close.current.click();
        //formik.resetForm()
        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changePage = (e, values) => {
    e.preventDefault();
    const pageNumber = pages.index + parseInt(values);
    console.log(pageNumber);
    if (pageNumber >= 0 && pageNumber < pages.counter) {
      setPages({ ...pages, index: pageNumber });
      setDatas({
        ...datas,
        small: pages.list[pageNumber],
      });
    }
  };

  const editData = (e, data) => {
    e.preventDefault();
    console.log(data);
    formikPromo.setFieldValue("id", data.id);
    formikPromo.setFieldValue("dateDebut", data.dateDebut);
    formikPromo.setFieldValue("dateFin", data.dateFin);
    formikPromo.setFieldValue("prixPromotion", data.prixPromotion);
  };

  const selectedData = (e, data) => {
    e.preventDefault();
    setViewData(data);
  };
  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <h1 className="text-start mb-3">Liste des promotions ({statutPromo})</h1>
          <div className="d-flex">
            <div className="d-flex align-items-center me-auto">
              <div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Rechercher..."
                  />
                  <span className="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
              <div>
                <span className="ms-2" onClick={(e) => changePage(e, "-1")}>
                  <PrevIcon />
                </span>
                <span className="ms-2" onClick={(e) => changePage(e, "+1")}>
                  <SuivIcon />
                </span>
              </div>
              <span className="fw-bold">
                Page {pages.index + 1} / {pages.list.length}
              </span>
            </div>
            <div>
              <select class="form-select" aria-label="Default select example" onChange={e => {
                get(e.target.value)
                setStatutPromo(e.target.value)
              }}>
                <option selected>Statut de la promo</option>
                {
                  typePromo.map((data,idx) => {
                    return <option value={data} key={idx}>{data}</option>
                  })
                }
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Produits</th>
                <th scope="col">Statut</th>
                <th scope="col">Prix</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {datas.small.map((data, idx) => {
                return (
                  <tr key={idx}>
                    <th scope="row">{idx + 1}</th>
                    <td>
                      <div className="d-flex">
                        <img
                          width={"40px"}
                          height={"40px"}
                          src={URL + "produit/images/" + data.produit.image}
                          alt=""
                        />
                        <div className="ms-2">{data.produit.label}</div>
                      </div>
                    </td>
                    <td>{data.status}</td>
                    <td>{data.prixPromotion} XOF</td>
                    <td className="text-start1">
                      <span>Début : </span>{" "}
                      <span className="fw-bold">
                        {new Date(data.dateDebut).toLocaleDateString()}
                      </span>{" "}
                      <br />
                      <span>Fin : </span>{" "}
                      <span className="fw-bold">
                        {new Date(data.dateFin).toLocaleDateString()}
                      </span>{" "}
                      <br />
                    </td>
                    <td>
                      <div className="btn-group1">
                        <button
                          className="btn btn-primary-light mx-1 rounded-3"
                          data-bs-toggle="modal"
                          data-bs-target="#view"
                          onClick={(e) => selectedData(e, data.produit)}
                        >
                          <EyeIcon /> Voir
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className="modal fade"
        id="delete"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Supprimer les données
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">Voulez-vous continuer ?</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={close}
              >
                Non
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={destroy}
              >
                Oui
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade modal-lg" id="view">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Détails
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <div className="d-flex">
                <img
                  width={"160px"}
                  height={"160px"}
                  src={URL + "produit/images/" + viewData.image}
                  alt=""
                />
                <div className="ps-3">
                  <span className="fw-bold fs-20">{viewData.label}</span>
                  <br />
                  <span>categorie : </span>
                  <span className="text-muted">{viewData.label}</span>
                  <br />
                  <br />

                  <span className="fw-bold">Description</span>
                  <p className="">{viewData.description}</p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="promotion"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Modifier la promotion
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <span className="d-block mb-3">
                Date de debut de la promotion
              </span>
              <Input
                type={"date"}
                placeholder="Date de debut de la promotion"
                name={"dateDebut"}
                formik={formikPromo}
              />
              <span className="d-block mb-3">Date de fin de la promotion</span>
              <Input
                type={"date"}
                placeholder="Date de fin de la promotion"
                name={"dateFin"}
                formik={formikPromo}
              />
              <Input
                type={"text"}
                placeholder="Prix promotion"
                name={"prixPromotion"}
                formik={formikPromo}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={closePromo}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={formikPromo.handleSubmit}
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

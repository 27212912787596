import { useNavigate, useParams } from "react-router-dom";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { EditIcon } from "../../assets/icons/EditIcon";
import { EyeIcon } from "../../assets/icons/EyeIcon";
import { PrevIcon } from "../../assets/icons/PrevIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { SuivIcon } from "../../assets/icons/SuivIcon";
import AppLink from "../../routers/AppLink";
import { ArticleIcon } from "../../assets/icons/ArticleIcon";
import { PeopleIcon } from "../../assets/icons/ClientIcon";
import request, { URL } from "../../services/request";
import endPoint from "../../services/endPoint";
import { useEffect, useState } from "react";
import { TabCategorie } from "./TabCategorie";
import { TabProduit } from "./TabProduit";
import { TabPersonnel } from "./TabPersonnel";
import { IconContainer } from "../../components/IconContainer";
import { TabPromotion } from "./TabPromotion";
import { TabRuptureStock } from "./TabRuptureStock";
import { TabCommande } from "./TabCommande";

export const DetailSuperMarche = ({ title }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [detail, setDetail] = useState({ boutique: {} });
  const [view, setView] = useState("Categories");
  const stats = [
    { name: "Categories", value: "325", icon: <ArticleIcon /> },
    { name: "Produits", value: "325", icon: <ArticleIcon /> },
    { name: "Personnels", value: "10", icon: <PeopleIcon /> },
    { name: "Promotions", value: "10", icon: <ArticleIcon /> },
    { name: "Rupture de stock", value: "10", icon: <ArticleIcon /> },
    { name: "Commande", value: "10", icon: <ArticleIcon /> },
  ];

  const [statCommande, setStatCommande] = useState({});
  const [statLivraison, setStatLivraison] = useState({});

  useEffect(() => {
    get();
    getLivraisonStat()
    getCommandeStat()
  }, []);

  const get = () => {
    request
      .get(endPoint.responsables + "/" + id)
      .then((res) => {
        console.log(res.data.responsable.data);
        setDetail(res.data.responsable.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCommandeStat = () => {
    request
      .get(endPoint.statistiques + "/nombre/commande-prix/boutiques/" + id)
      .then((res) => {
        console.log(res.data.data);
        setStatCommande(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLivraisonStat = () => {
    request
      .get(endPoint.statistiques + "/nombre/livraison-prix/boutiques/" + id)
      .then((res) => {
        //console.log(res.data.data);
        setStatLivraison(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const gotoDetail = (e, id) => {
    e.preventDefault();
    navigate(AppLink.detail + "/" + id);
  };
  return (
    <>
      <div className="row mb-3">
        <div className="col-6 p-3">
          <div className="bg-gray rounded-3 p-2">
            <div className="d-flex">
              <div className="ms-auto">
                <button className="btn btn-danger mx-1">Bloquer</button>
              </div>
            </div>
            <div className="d-flex align-items-center text-start">
              <img
                className="rounded-circle"
                width={"172px"}
                //src={URL + "users/profile/" + detail.user?.photo}
                src={URL + "boutique/logo/" + detail.boutique.logo}
                alt=""
              />
              <div className="ms-3 w-100">
                <h2 className="fs-18">{detail?.boutique.nom}</h2>
                <p className="d-flex p-0 m-0 text-start">
                  <span className="text-muted me-auto">Orange money :</span>{" "}
                  <span className="fw-bold">
                    {detail?.boutique.numeroCompte}
                  </span>
                </p>
                <p className="d-flex p-0 m-0 text-start">
                  <span className="text-muted me-auto">Responsable :</span>{" "}
                  <span className="fw-bold">
                    {detail.nom + " " + detail.prenom}
                  </span>
                </p>
                <p className="d-flex p-0 m-0 text-start">
                  <span className="text-muted me-auto">Genre :</span>{" "}
                  <span className="fw-bold">
                    {detail.genre === "H" ? "Homme" : "Femme"}
                  </span>
                </p>
                <p className="d-flex p-0 m-0 text-start">
                  <span className="text-muted me-auto">Email :</span>{" "}
                  <span className="fw-bold">{detail.user?.email}</span>
                </p>
                <p className="d-flex p-0 m-0 text-start">
                  <span className="text-muted me-auto">Tel : </span>
                  <span className="fw-bold">
                    {detail.user?.numeroTelephone}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 p-3">
          <div className="bg-gray rounded-3 h-100">
            <div className="d-flex align-items-center justify-content-center bg-gray rounded-3 h-100 p-2">
              <div>
                <div>
                  <span className="w-100">Commandes : </span>
                  <span className="fw-bold">{statCommande.nombreTotal} </span>
                </div>
                <div>
                  <span className="w-100">Total : </span>
                  <span className="fw-bold">{statCommande.montantTotal} FCFA</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 p-3">
          <div className="d-flex align-items-center justify-content-center bg-gray rounded-3 h-100 p-2">
            <div>
                <div>
                  <span className="w-100">livraison : </span>
                  <span className="fw-bold">{statLivraison.nombreLivraison}</span>
                </div>
                <div>
                  <span className="w-100">Total : </span>
                  <span className="fw-bold">{statLivraison.montantTotalLivraison} FCFA</span>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-5 mb-3">
        <div className="col cursor mb-3" onClick={(e) => setView(stats[0].name)}>
          <div className="border text-start p-2 bg-primary-light rounded-3">
            <IconContainer>{stats[0].icon}</IconContainer> <br />
            <span>{}</span> <br />
            <span className="fw-bold">{stats[0].name}</span>
          </div>
        </div>
        <div className="col cursor mb-3" onClick={(e) => setView(stats[1].name)}>
          <div className="border text-start p-2 bg-primary-light rounded-3">
            <IconContainer>{stats[1].icon}</IconContainer> <br />
            <span></span> <br />
            <span className="fw-bold">{stats[1].name}</span>
          </div>
        </div>
        <div className="col cursor mb-3" onClick={(e) => setView(stats[2].name)}>
          <div className="border text-start p-2 bg-primary-light rounded-3">
            <IconContainer>{stats[2].icon}</IconContainer> <br />
            <span></span> <br />
            <span className="fw-bold">{stats[2].name}</span>
          </div>
        </div>
        <div className="col cursor mb-3" onClick={(e) => setView(stats[3].name)}>
          <div className="border text-start p-2 bg-primary-light rounded-3">
            <IconContainer>{stats[3].icon}</IconContainer> <br />
            <span></span> <br />
            <span className="fw-bold">{stats[3].name}</span>
          </div>
        </div>
        <div className="col cursor mb-3" onClick={(e) => setView(stats[4].name)}>
          <div className="border text-start p-2 bg-primary-light rounded-3">
            <IconContainer>{stats[4].icon}</IconContainer> <br />
            <span></span> <br />
            <span className="fw-bold">{stats[4].name}</span>
          </div>
        </div>
        <div className="col cursor mb-3" onClick={(e) => setView(stats[5].name)}>
          <div className="border text-start p-2 bg-primary-light rounded-3">
            <IconContainer>{stats[5].icon}</IconContainer> <br />
            <span></span> <br />
            <span className="fw-bold">{stats[5].name}</span>
          </div>
        </div>
      </div>
      {view === stats[0].name && <TabCategorie />}
      {view === stats[1].name && <TabProduit />}
      {view === stats[2].name && <TabPersonnel />}
      {view === stats[3].name && <TabPromotion />}
      {view === stats[4].name && <TabRuptureStock />}
      {view === stats[5].name && <TabCommande />}
    </>
  );
};

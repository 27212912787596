export const PeopleIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 15V13.5C9.75 11.4289 8.07107 9.75 6 9.75C3.92893 9.75 2.25 11.4289 2.25 13.5V15H9.75ZM9.75 15H15.75V14.25C15.75 12.0409 14.0711 10.5 12 10.5C10.94 10.5 9.98273 10.9691 9.3007 11.7233M8.25 5.25C8.25 6.49264 7.24264 7.5 6 7.5C4.75736 7.5 3.75 6.49264 3.75 5.25C3.75 4.00736 4.75736 3 6 3C7.24264 3 8.25 4.00736 8.25 5.25ZM13.5 6.75C13.5 7.57843 12.8284 8.25 12 8.25C11.1716 8.25 10.5 7.57843 10.5 6.75C10.5 5.92157 11.1716 5.25 12 5.25C12.8284 5.25 13.5 5.92157 13.5 6.75Z"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

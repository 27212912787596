const statut_commande = [
    {id:"EN_COUR_DE_PREPARATION", label:"En cours de préparation"},
    {id:"ANNULEE", label:"Annulée"},
    {id:"PRET", label:"Prête"},
    {id:"EN_COURS_DE_LIVRAISON", label:"En cours de livraison"},
    {id:"LIVREE", label:"Livrée"},
]

const commandeLabel = {
    EN_COUR_DE_PREPARATION: "En cours de préparation",
    ANNULER: "Annulée",
    PRET: "Prête",
    EN_COURS_DE_LIVRAISON: "En cours de livraison",
    LIVREE: "Livrée",
    RETIREE: "Retirée",
  };


export {
    statut_commande,commandeLabel
}
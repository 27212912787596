import { useNavigate, useParams } from "react-router-dom";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { EditIcon } from "../../assets/icons/EditIcon";
import { EyeIcon } from "../../assets/icons/EyeIcon";
import { PrevIcon } from "../../assets/icons/PrevIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { SuivIcon } from "../../assets/icons/SuivIcon";
import AppLink from "../../routers/AppLink";
import request from "../../services/request";
import endPoint from "../../services/endPoint";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { pagination } from "../../services/function";
import { Input } from "../../components/Input";
import { statut_commande } from "../../utils/data";
import { SuperMarcherIcon } from "../../assets/icons/SuperMarcherIcon";
import { IconContainer } from "../../components/IconContainer";

export const TabCommande = ({ title }) => {
  const navigate = useNavigate();
  const [datas, setDatas] = useState({
    all: [],
    small: [],
  });
  const [viewData, setViewData] = useState("");

  const [pages, setPages] = useState({
    list: [],
    counter: 0,
    index: 0,
  });
  const [viewCarte, setViewCarte] = useState(false);

  const type_commandes = [{ id: "", label: "Total" }, ...statut_commande];
  const [viewCommande, setViewCommande] = useState("")
  const {id} = useParams()
  const [statut, setStatut] = useState("Total")

  const close = useRef();
  useEffect(() => {
    get();
  }, []);

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      console.log(values);
      getFiltre(values)
    },
  });

  const get = () => {
    request
      .get(endPoint.commandes+"/by/boutique/"+id)
      .then((res) => {
        console.log(res.data);
        const tab = pagination(res.data.commandes, 10);

        console.log(tab);

        if (tab.counter !== 0) {
          setDatas({
            all: res.data.commandes,
            small: tab.list[0],
          });
          setPages(tab);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFiltre = (value) => {
    //11
    const data = {
      date: "2023-12-07",
      status: "LIVREE",
    };
    request
      .put(endPoint.commandes + "/ours-by-date-status-and-boutique/", value)
      //.get(endPoint.commandes + "/by/connected/responsable/boutique")
      .then((res) => {
        console.log(res.data);
        const tab = pagination(res.data.data, 10);

        console.log(tab);
        close.current.click()
        if (tab.counter !== 0) {
          setDatas({
            all: res.data.data,
            small: tab.list[0],
          });
          setPages(tab);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changePage = (e, values) => {
    e.preventDefault();
    const pageNumber = pages.index + parseInt(values);
    console.log(pageNumber);
    if (pageNumber >= 0 && pageNumber < pages.counter) {
      setPages({ ...pages, index: pageNumber });
      setDatas({
        ...datas,
        small: pages.list[pageNumber],
      });
    }
  };

  const gotoDetail = (e, id) => {
    e.preventDefault();
    navigate("/dashboard/commandes/detail/" + id);
  };

  const changeCommandeView = (e,data_id) => {
    e.preventDefault()
    setViewCommande(data_id)
    type_commandes.map((data) => {
      if(data.id === data_id){
        setStatut(data.label)
      }
    })
  }

  return (
    <>
      <div className="row row-cols-1 row-cols-md-5 mb-4">
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <h1 className="text-start mb-3">Liste des commandes ({statut})</h1>
          <div className="d-flex">
            <div className="d-flex align-items-center me-auto">
              <div>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Rechercher..."
                  />
                  <span class="input-group-text">
                    <SearchIcon />
                  </span>
                </div>
              </div>
              <div>
                <span className="ms-2" onClick={(e) => changePage(e, "-1")}>
                  <PrevIcon />
                </span>
                <span className="ms-2" onClick={(e) => changePage(e, "+1")}>
                  <SuivIcon />
                </span>
              </div>
              <span className="fw-bold">
                Page {pages.index + 1} / {pages.list.length}
              </span>
            </div>
            <div>
              <select class="form-select" aria-label="Default select example" onChange={e => {
                changeCommandeView(e, e.target.value)
                //setStatutPromo(e.target.value)
              }}>
                <option selected>Statut de la commande</option>
                {
                  type_commandes.map((data,idx) => {
                    return <option value={data.id} key={idx}>{data.label}</option>
                  })
                }
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Numéro</th>
                <th scope="col">Date</th>
                <th scope="col">client</th>
                <th scope="col">Total</th>
                <th scope="col">Statut</th>
                <th scope="col">Détail</th>
              </tr>
            </thead>
            <tbody>
              {datas.small.map((data, idx) => {
                if(viewCommande !== "" && viewCommande !== data.status){
                  return null
                }
                return (
                  <tr key={idx}>
                    <th scope="row">{idx + 1}</th>
                    <td>{data.codeCommande}</td>
                    <td>{new Date(data.dateCommande).toLocaleDateString()}</td>
                    <td>
                      <span>{data.client.nom + " " + data.client.prenom}</span>{" "}
                      <br />
                    </td>
                    <td>
                      <span>{data.montantTotal + " FCFA"}</span>
                    </td>
                    <td>
                      <span>{data.status}</span>
                    </td>
                    <td>
                      <div className="btn-group">
                        <button
                          className="btn btn-primary-light mx-1 rounded-3"
                          onClick={(e) => gotoDetail(e, data.id)}
                        >
                          <EyeIcon /> Voir
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className="modal fade"
        id="filtre"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Filtrage de commande
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <span className="d-block mb-3">Date de la commande</span>
              <Input
                type={"date"}
                placeholder="Date de debut de la promotion"
                name={"date"}
                formik={formik}
              />
              <span className="d-block mb-3">Statut de commande</span>
              <Input
                type={"select"}
                placeholder="Sélectionnez le statut de la commande"
                name={"status"}
                formik={formik}
                options={statut_commande}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={close}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={formik.handleSubmit}
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

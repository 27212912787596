export const DashIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33333 7.49992L16.6667 7.49992M6.66667 7.49992V16.6666M5.16667 16.6666H14.8333C15.7668 16.6666 16.2335 16.6666 16.59 16.4849C16.9036 16.3251 17.1586 16.0702 17.3183 15.7566C17.5 15.4 17.5 14.9333 17.5 13.9999V5.99992C17.5 5.0665 17.5 4.59979 17.3183 4.24327C17.1586 3.92966 16.9036 3.6747 16.59 3.51491C16.2335 3.33325 15.7668 3.33325 14.8333 3.33325H5.16667C4.23325 3.33325 3.76654 3.33325 3.41002 3.51491C3.09641 3.6747 2.84144 3.92966 2.68166 4.24327C2.5 4.59979 2.5 5.0665 2.5 5.99992V13.9999C2.5 14.9333 2.5 15.4 2.68166 15.7566C2.84144 16.0702 3.09641 16.3251 3.41002 16.4849C3.76654 16.6666 4.23325 16.6666 5.16667 16.6666Z"
        stroke="#5C5C5C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const StatistiqueIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 13.5L5.25 15.75M11.25 13.5L12.75 15.75M5.25 10.5H12.75M6 10.5V8.25M9 10.5V4.5M12 10.5V6.75M4.65 13.5H13.35C14.1901 13.5 14.6101 13.5 14.931 13.3365C15.2132 13.1927 15.4427 12.9632 15.5865 12.681C15.75 12.3601 15.75 11.9401 15.75 11.1V4.65C15.75 3.80992 15.75 3.38988 15.5865 3.06901C15.4427 2.78677 15.2132 2.5573 14.931 2.41349C14.6101 2.25 14.1901 2.25 13.35 2.25H4.65C3.80992 2.25 3.38988 2.25 3.06901 2.41349C2.78677 2.5573 2.5573 2.78677 2.41349 3.06901C2.25 3.38988 2.25 3.80992 2.25 4.65V11.1C2.25 11.9401 2.25 12.3601 2.41349 12.681C2.5573 12.9632 2.78677 13.1927 3.06901 13.3365C3.38988 13.5 3.80992 13.5 4.65 13.5Z"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

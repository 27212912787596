

import { useNavigate, useParams } from "react-router-dom";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { EditIcon } from "../../assets/icons/EditIcon";
import { EyeIcon } from "../../assets/icons/EyeIcon";
import { PrevIcon } from "../../assets/icons/PrevIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { SuivIcon } from "../../assets/icons/SuivIcon";
import AppLink from "../../routers/AppLink";
import request from "../../services/request";
import endPoint from "../../services/endPoint";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { pagination } from "../../services/function";
import profile from "../../assets/images/profile.png"

export const TabPersonnel = ({title}) => {

    const navigate = useNavigate()
    const [datas, setDatas] = useState({
      all: [],
      small: [],
    });
    const [viewData, setViewData] = useState("");
    const [pages, setPages] = useState({
      list: [],
      counter: 0,
      index: 0,
    });
    const {id} = useParams()
    
    const close = useRef();
    useEffect(() => {
      get();
    }, []);
    
    const get = () => {
      request
        .get(endPoint.sousresponsables+"/boutique/"+id)
        .then((res) => {
          console.log(res.data)
          const tab = pagination(res.data.sousResponsables.data, 10);
  
          console.log(tab);
  
          if (tab.counter !== 0) {
            setDatas({
              all: res.data.sousResponsables.data,
              small: tab.list[0],
            });
            setPages(tab);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const changePage = (e, values) => {
      e.preventDefault();
      const pageNumber = pages.index + parseInt(values);
      console.log(pageNumber);
      if (pageNumber >= 0 && pageNumber < pages.counter) {
        setPages({ ...pages, index: pageNumber });
        setDatas({
          ...datas,
          small: pages.list[pageNumber],
        });
      }
    };
  
    const gotoDetail = (e, id) => {
        e.preventDefault()
        navigate(AppLink.detail+"/"+id)
    }

    
  const selectedData = (e, data) => {
      e.preventDefault();
      setViewData(data);
    };

    
  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <h1 className="text-start mb-3">Liste du personnel de la boutique</h1>
          <div className="d-flex align-items-center">
            <div>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Rechercher..."
                />
                <span class="input-group-text">
                  <SearchIcon />
                </span>
              </div>
            </div>
            <div>
            <span className="ms-2" onClick={(e) => changePage(e, "-1")}>
                  <PrevIcon />
                </span>
                <span className="ms-2" onClick={(e) => changePage(e, "+1")}>
                  <SuivIcon />
                </span>
            </div>
            <span className="fw-bold">Page {pages.index + 1} / {pages.list.length}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nom</th>
                <th scope="col">Email</th>
                <th scope="col">Téléphone</th>
              </tr>
            </thead>
            <tbody>
              {datas.small.map((data, idx) => {
                return (
                  <tr key={idx}>
                    <th scope="row">{idx + 1}</th>
                    <td>{data.nom + " " + data.prenom}</td>
                    <td>{data.user.email}</td>
                    <td>
                      <span>{data.user.numeroTelephone}</span>
                    </td>
                    <td><span>{}</span></td>
                    <td>
                      <div className="btn-group">
                        <button className="btn btn-primary-light mx-1 rounded-3" data-bs-target="#voir" data-bs-toggle="modal" onClick={e => selectedData(e,data)}>
                          <EyeIcon /> Voir
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="modal fade"
        id="voir"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Information sur l'utilisateur
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="d-flex">
                <img
                  className="rounded-3"
                  width={"172px"}
                  src={viewData?.user?.photo ? URL+"users/profile/"+viewData?.user.photo : profile}
                  alt=""
                />
                <div className="ms-2 text-start w-100">
                  <div className="d-flex w-100">
                    <h3 className="me-auto">
                      {viewData.nom + " " + viewData.prenom}
                    </h3>
                    <span>
                      {viewData.user?.isActive ? "Actif" : "Non actif"}
                    </span>
                  </div>
                  <span>{viewData.genre === "F" ? "Femme" : "Homme"}</span>
                  <br />
                  <span>{viewData.user?.email}</span>
                  <br />
                  <span>{viewData.user?.numeroTelephone}</span>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

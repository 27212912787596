import { useNavigate, useParams } from "react-router-dom";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { EditIcon } from "../../assets/icons/EditIcon";
import { EyeIcon } from "../../assets/icons/EyeIcon";
import { PrevIcon } from "../../assets/icons/PrevIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { SuivIcon } from "../../assets/icons/SuivIcon";
import AppLink from "../../routers/AppLink";
import request from "../../services/request";
import endPoint from "../../services/endPoint";
import { useEffect, useState } from "react";
import { pagination } from "../../services/function";

import profile from "../../assets/images/profile.png"

export const DetailClient = ({ title }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [detail, setDetail] = useState({});
  const [statCommande, setStatCommande] = useState({});
  const [statLivraison, setStatLivraison] = useState({});
  
  const [datas, setDatas] = useState({
    all: [],
    small: [],
  });
  const [viewData, setViewData] = useState("");
  const [pages, setPages] = useState({
    list: [],
    counter: 0,
    index: 0,
  });

  useEffect(() => {
    get();
    getCommandeStat();
    getLivraisonStat();
    getCommande();
  }, []);

  const get = () => {
    request
      .get(endPoint.clients + "/" + id)
      .then((res) => {
        console.log(res.data.client.data);
        setDetail(res.data.client.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCommandeStat = () => {
    request
      .get(endPoint.statistiques + "/nombre/commande-prix/clients/" + id)
      .then((res) => {
        console.log(res.data.data);
        setStatCommande(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLivraisonStat = () => {
    request
      .get(endPoint.statistiques + "/nombre/livraison-prix/clients/" + id)
      .then((res) => {
        console.log(res.data.data);
        setStatLivraison(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCommande = () => {
    request
      .get(endPoint.commandes + "/by/client/" + id)
      .then((res) => {
        console.log(res.data.commandes);
        const tab = pagination(res.data.commandes, 10);

        //console.log(tab);

        if (tab.counter !== 0) {
          setDatas({
            all: res.data.commandes,
            small: tab.list[0],
          });
          setPages(tab);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const gotoDetail = (e, id) => {
    e.preventDefault();
    navigate("/dashboard/commandes/detail/" + id);
  };
  const changePage = (e, values) => {
    e.preventDefault();
    const pageNumber = pages.index + parseInt(values);
    console.log(pageNumber);
    if (pageNumber >= 0 && pageNumber < pages.counter) {
      setPages({ ...pages, index: pageNumber });
      setDatas({
        ...datas,
        small: pages.list[pageNumber],
      });
    }
  };
  const compteStatus = (e) => {
    e.preventDefault();

    request
      .put(endPoint.users + "/activate/" + detail.user.id)
      .then((res) => {
        console.log(res.data);
        //close.current.click();
        get();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="row mb-3">
        <div className="col-6 p-3">
          <div className="bg-gray rounded-3 p-2">
            <div className="d-flex">
              <div className="ms-auto">
                {detail.user?.isActive ? (
                  <>
                    <button className="btn btn-success mx-1">Actif</button>
                  </>
                ) : (
                  <>
                    {" "}
                    <button className="btn btn-danger mx-1">Non Actif</button>
                  </>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center text-start">
              <img
                className="rounded-circle"
                width={"172px"}
                src={profile}
                alt=""
              />
              <div className="ms-3">
                <h2>{detail.nom + " " + detail.prenom}</h2>
                <p className="p-0 m-0 text-start">
                  <span className="text-muted">Email :</span>{" "}
                  <span className="fw-bold">{detail.user?.email}</span>
                </p>
                <p className="p-0 m-0 text-start">
                  <span className="text-muted">Tel : </span>
                  <span className="fw-bold">
                    {detail.user?.numeroTelephone}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 p-3">
          <div className="bg-gray rounded-3 h-100">
            <div className="d-flex align-items-center justify-content-center bg-gray rounded-3 h-100 p-2">
              <div>
                <div>
                  <span className="w-100">Commandes : </span>
                  <span className="fw-bold">{statCommande.nombreCommande}</span>
                </div>
                <div>
                  <span className="w-100">Total : </span>
                  <span className="fw-bold">
                    {statCommande.montantTal ? statCommande.montantTal : "0"}{" "}
                    FCFA
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 p-3">
          <div className="d-flex align-items-center justify-content-center bg-gray rounded-3 h-100 p-2">
            <div>
              <div>
                <span className="w-100">livraisons : </span>
                <span className="fw-bold">{statLivraison.nombreLivraison}</span>
              </div>
              <div>
                <span className="w-100">Total : </span>
                <span className="fw-bold">
                  {statLivraison.montantTotalLivraison
                    ? statLivraison.montantTotalLivraison
                    : "0"}{" "}
                  FCFA
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <h1 className="text-start mb-3">{title}</h1>
          <div className="d-flex align-items-center">
            <div>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Rechercher..."
                />
                <span class="input-group-text">
                  <SearchIcon />
                </span>
              </div>
            </div>
            <div>
                <span className="ms-2" onClick={(e) => changePage(e, "-1")}>
                  <PrevIcon />
                </span>
                <span className="ms-2" onClick={(e) => changePage(e, "+1")}>
                  <SuivIcon />
                </span>
              </div>
              <span className="fw-bold">
                Page {pages.index + 1} / {pages.list.length}
              </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Numéro</th>
                <th scope="col">Date</th>
                <th scope="col">Boutique</th>
                <th scope="col">Montant total</th>
                <th scope="col">Statut</th>
                <th scope="col">Détail</th>
              </tr>
            </thead>
            <tbody>
              {datas.small.map((data, idx) => {
                return (
                  <tr key={idx}>
                    <th scope="row">{idx + 1}</th>
                    <td>{data.codeCommande}</td>
                    <td>{new Date(data.dateCommande).toLocaleDateString()}</td>
                    <td>{data.boutique?.nom}</td>
                    <td>{data.montantTotal} Fcfa</td>
                    <td>{data.status}</td>
                    <td>
                      <div className="btn-group">
                        <button className="btn btn-primary-light mx-1 rounded-3" onClick={e => {
                          gotoDetail(e, data.id)
                        }}>
                          <EyeIcon /> Voir
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

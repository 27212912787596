import { useState } from "react";
import { ArticleIcon } from "../assets/icons/ArticleIcon";
import { PeopleIcon } from "../assets/icons/ClientIcon";
import { DashIcon } from "../assets/icons/DashIcon";
import { DeleteIcon, DeteleIcon } from "../assets/icons/DeleteIcon";
import { EditIcon } from "../assets/icons/EditIcon";
import { EyeIcon } from "../assets/icons/EyeIcon";
import { NotificationIcon } from "../assets/icons/NotificationIcon";
import { PrevIcon } from "../assets/icons/PrevIcon";
import { SearchIcon } from "../assets/icons/SearchIcon";
import { SuivIcon } from "../assets/icons/SuivIcon";
import { SuperMarcherIcon } from "../assets/icons/SuperMarcherIcon";
import { ViewArea } from "../components/ViewArea";
import { ViewBar } from "../components/ViewBar";
import { IconContainer } from "../components/IconContainer";

export const Moderation = () => {
  const [viewCommande, setViewCommande] = useState(false);
  const stats = [
    { name: "Utilisateurs", value: "0", icon: <PeopleIcon /> },
    { name: "Livreurs", value: "0", icon: <PeopleIcon /> },
    { name: "Supermarchés", value: "0", icon:  <SuperMarcherIcon />},
    { name: "Articles", value: "0", icon:  <ArticleIcon /> },
  ];

  const activeViewCommande = (e, name) => {
    e.preventDefault();
    if(name === "Commande totale"){
      setViewCommande(!viewCommande)
    }
  }
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="text-start mb-3">Moderation</h1>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-5 mb-4">
        {stats.map((data, idx) => {
          return (
            <div className="col cursor" key={idx} onClick={e => activeViewCommande(e, data.name)}>
              <div className="border text-start p-2 bg-primary-light rounded-3">
                <IconContainer>{data.icon}</IconContainer><br/>
                <span>{data.name}</span> <br />
                <span className="fw-bold">{data.value}</span>
              </div>
            </div>
          );
        })}
      </div>
      <div className="row">
        <div className="col-12 text-start mb-3">
          <span className="fw-bold fs-20">
            Utilisateurs
          </span>
        </div>
        <div className="col-12">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nom Prenom</th>
                <th scope="col">Contact</th>
                <th scope="col">Nombre de signalement</th>
                <th scope="col">Statut</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {[].map((data, idx) => {
                return (
                  <tr key={idx}>
                    <th scope="row">1</th>
                    <td>Traore Ali</td>
                    <td>
                      +226 xx xx xx xx
                    </td>
                    <td>5</td>
                    <td>Actif</td>
                    <td>
                      <div className="btn-group">
                        <button className="btn btn-primary-light mx-1 rounded-3">
                          <EyeIcon /> Voir
                        </button>
                        <button className="btn btn-danger mx-1 rounded-3">
                          <DeleteIcon /> Bannir
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

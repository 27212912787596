import { Route, Routes } from "react-router-dom";
import { Accueil } from "../Pages/Accueil";
import { MonCompte } from "../Pages/MonCompte";
import { BoiteReception } from "../Pages/BoiteReception";
import Dashboard from "../Pages/Dashboard";
import { Login } from "../Pages/Login";
import AppLink from "./AppLink";
import { Produit } from "../Pages/Produit";
import { Commande } from "../Pages/Commande";
import { Paiement } from "../Pages/Paiement";
import { Personnel } from "../Pages/Personnel";
import { Statistique } from "../Pages/Statistique";
import { SuperMarche } from "../Pages/SuperMarche";
import { Livreur } from "../Pages/Livreur";
import { Moderation } from "../Pages/Moderation";
import { ListLivreur } from "../Pages/livreur/ListeLivreur";
import { DetailLivreur } from "../Pages/livreur/DetailLivreur";
import { ListClient } from "../Pages/clientLivreur/ListClient";
import { DetailClient } from "../Pages/clientLivreur/DetailClient";
import { Client } from "../Pages/Client";
import { ListCommande } from "../Pages/commande/ListCommande";
import { DetailCommande } from "../Pages/commande/DetailCommande";
import { ListeSuperMarche } from "../Pages/supermarche/ListeSuperMarche";
import { DetailSuperMarche } from "../Pages/supermarche/DetailSuperMarche";


const AppRoute = ({ type }) => {
  if (type === "app") {
    return (
      <Routes>
        <Route path={AppLink.dashboard+AppLink.all} element={<Dashboard />} />
        <Route path={AppLink.dashboard} element={<Dashboard />} />
        <Route path={AppLink.login} element={<Login />} />
      </Routes>
    );
  }
  if (type === "dashboard") {
    return (
      <Routes>
        <Route path={AppLink.accueil} element={<Accueil />} />
        <Route path={AppLink.commandes+"/*"} element={<Commande />} />
        <Route path={AppLink.produits} element={<Produit />} />
        <Route path={AppLink.superMarches+"/*"} element={<SuperMarche />} />
        <Route path={AppLink.paiements} element={<Paiement />} />
        <Route path={AppLink.personnel} element={<Personnel />} />
        <Route path={AppLink.moderation} element={<Moderation />} />
        <Route path={AppLink.clients+"/*"} element={<Client title={"Les clients"} />} />
        <Route path={AppLink.livreur+"/*"} element={<Livreur title={"Les livreurs"} />} />
        <Route path={AppLink.admin} element={<Personnel title={"Les administrateur"} btnAdd={true} />} />
        <Route path={AppLink.demandeLivreur} element={<Livreur title={"Demandes livreur"}/>} />
        <Route path={AppLink.boiteReception} element={<BoiteReception />} />
        <Route path={AppLink.monCompte} element={<MonCompte />} />
        <Route path={AppLink.statistique} element={<Statistique />} />
      </Routes>
    );
  }
  
  if (type === "livreur") {
    return (
      <Routes>
        <Route path={AppLink.index} element={<ListLivreur />} />
        <Route path={AppLink.detail_id} element={<DetailLivreur />} />
      </Routes>
    );
  }
  if (type === "client") {
    return (
      <Routes>
        <Route path={AppLink.index} element={<ListClient />} />
        <Route path={AppLink.detail_id} element={<DetailClient />} />
      </Routes>
    );
  }
  if (type === "commande") {
    return (
      <Routes>
        <Route path={AppLink.index} element={<ListCommande />} />
        <Route path={AppLink.detail_id} element={<DetailCommande />} />
      </Routes>
    );
  }
  if (type === "supermarche") {
    return (
      <Routes>
        <Route path={AppLink.index} element={<ListeSuperMarche />} />
        <Route path={AppLink.detail_id} element={<DetailSuperMarche />} />
      </Routes>
    );
  }
};

export default AppRoute;

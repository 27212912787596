import React from "react";
import AppRoute from "../routers/AppRoute";

export const Commande = () => {
  return (
    <>
      <AppRoute type={"commande"} />
    </>
  );
};

import { useEffect, useState } from "react";
import { PrevIcon } from "../assets/icons/PrevIcon";
import { SearchIcon } from "../assets/icons/SearchIcon";
import { SuivIcon } from "../assets/icons/SuivIcon";
import endPoint from "../services/endPoint";
import request from "../services/request";
import profile from "../assets/images/profile.png";

export const BoiteReception = () => {

  useEffect(() => {
    get()
  },[])

  const top = (e) =>{
    e.preventDefault()
    window.scrollTo(0, 0)
}

const [userInfo, setUserInfo] = useState({})
const get = () => {
    request
      .get(endPoint.users + "/get/connected/user")
      .then((res) => {
        console.log(res.data);
        setUserInfo(res.data.data)
        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="row">
      <div className="col-12">
        <h1 className="text-start mb-3">Boite de reception</h1>
        <div className="d-flex align-items-center">
          <div>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Rechercher..."
              />
              <span class="input-group-text">
                <SearchIcon />
              </span>
            </div>
          </div>
          <button className="btn btn-primary ms-3">
            Nouveau message
          </button>
        </div>
        <div className="row">
          <div className="col-3 max-h-100 overflow-y-scroll border-end">
            {[].map((data, idx) => {
              return (
                <div className="d-flex mt-4" key={"message" + idx}>
                  <img
                    className="rounded-circle me-2"
                    width={"48px"}
                    height={"48px"}
                    src={
                      "https://source.unsplash.com/random/800x800/?profile=" +
                      idx
                    }
                    alt=""
                  />{" "}
                  <div className="w-100">
                    <div className="d-flex fs-14">
                      <span className="me-auto fw-bold">Traore ismael</span>
                      <span>12/10/2023</span>
                    </div>
                    <div className="d-flex">Lorem ipsum kpuo...</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-9 px-5">
            <div className="d-flex border-bottom py-3">
              <img
                className="rounded-circle me-2"
                width={"64px"}
                height={"64px"}
                src={userInfo?.user?.photo ? URL+"users/profile/"+userInfo?.user.photo : profile}

                alt=""
              />
              <div className="text-start">
                <span className="fw-bold fs-18">{userInfo.nom + " " + userInfo.prenom}</span> <br />
                <span>{userInfo.user?.numeroTelephone}</span> <br />
                <span>{userInfo.user?.email}</span> <br />
              </div>
            </div>
            <div className="max-h-40 overflow-y-scroll">
              {[].map((data, idx) => {
                return (
                  <div className="mt-5" key={"messageContent" + idx}>
                    <div className="d-flex mb-4">
                      <p className="w-70 text-start border p-2 bg-gray">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Minima recusandae perferendis quo facere voluptas!
                        At magnam amet veniam nisi officia, mollitia,
                        perferendis deserunt cumque expedita laudantium,
                        repudiandae optio doloribus ad.
                      </p>
                    </div>
                    <div className="d-flex">
                      <p className="w-70 ms-auto text-end border p-2 bg-primary-light">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Minima recusandae perferendis quo facere voluptas!
                        At magnam amet veniam nisi officia, mollitia,
                        perferendis deserunt cumque expedita laudantium,
                        repudiandae optio doloribus ad.
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="border-top py-3">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your Email"
                />
                <span class="input-group-text">Envoyer</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DeleteIcon = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33325 4H11.6666M9.33325 4L9.17538 3.52637C9.02238 3.0674 8.94589 2.83791 8.804 2.66824C8.67871 2.51841 8.5178 2.40244 8.33604 2.33095C8.13021 2.25 7.88831 2.25 7.4045 2.25H6.59534C6.11153 2.25 5.86963 2.25 5.6638 2.33095C5.48204 2.40244 5.32113 2.51841 5.19584 2.66824C5.05395 2.83791 4.97745 3.0674 4.82446 3.52637L4.66659 4M10.4999 4V9.95C10.4999 10.9301 10.4999 11.4201 10.3092 11.7945C10.1414 12.1238 9.87369 12.3915 9.5444 12.5593C9.17006 12.75 8.68001 12.75 7.69992 12.75H6.29992C5.31983 12.75 4.82978 12.75 4.45544 12.5593C4.12615 12.3915 3.85844 12.1238 3.69066 11.7945C3.49992 11.4201 3.49992 10.9301 3.49992 9.95V4M8.16659 6.33333V10.4167M5.83325 6.33333V10.4167"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
